@import './custom_bootstrap.scss';

body {
  margin: 0;
  padding: 0;
  font-family: Ubuntu, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.min-h-screen {
  min-height: 100vh;
}

.flex-1 {
  flex: 1;
}

.text-sm {
  font-size: 0.75rem;
}

.card {
  padding: 0;
  white-space: normal;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(230, 236, 241);
  box-shadow: rgba(116, 129, 141, 0.1) 0px 3px 8px 0px;
}

.card.btn:hover {
  border-color: gray('500');
  background-color: gray('200');
}

.font-serif {
  font-family: 'Varela Round', -apple-system, BlinkMacSystemFont, 'Open Sans', 'Helvetica Neue', sans-serif ;
}

.font-mono {
  font-family: Menlo, Monaco, 'Roboto Mono', monospace;
}

.border-transparent {
  border: solid transparent;
}

.truncate {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.no-underline {
  text-decoration: none !important;
}

.no-underline:hover {
  text-decoration: none !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xs {
  font-size: 0.725rem !important;
}

.outline-thick {
  box-shadow: 0 0 0 5px hsla(0,0%,0%,0.05)
}

.shadow-lg {
  box-shadow: rgba(116, 129, 141, 0.15) 0px 3px 64px 0px;
}

.lead-image {
  border-radius: 0.25rem;
  height: auto;
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .lead-image {
    max-width: 400px
  }
}

.image {
  border-radius: 0.25rem;
  height: auto;
  width: 100%;
}


@include media-breakpoint-down(sm) {
  .display-none-sm-down {
    display: none !important;
  }
}