$theme-colors: (
  primary: #3b84f8,
  accent-primary: #2B44FF,
  accent-light: #e4ecf9,
  accent-dark: #353a40,
  danger: #d14354,
  warning: #fcb400,
  light: #f5f7f9,
);

@import '../node_modules/bootstrap/scss/bootstrap.scss';
